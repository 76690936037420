<template>
  <Xlayout :backgroundColor="backgroundColor">
    <div :class="$style.btnGroup">
      <div>
        <van-uploader
          :class="$style.btn"
          :max-count="1"
          :after-read="handlerCardRecognition"
        >
          <Xbutton style="width: 100%" @click="handlerCardRecognition"
            >拍名片，可免输入信息</Xbutton
          >
        </van-uploader>
        <div :class="$style.btn">
          <Xbutton @click="$router.push('/verification')" :isReverse="true"
            >无名片，请自行输入信息</Xbutton
          >
        </div>
      </div>
    </div>
  </Xlayout>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import { Toast } from "vant";

import Xlayout from "../components/layout.vue";
import Xbutton from "../components/button.vue";
export default {
  components: { Xlayout, Xbutton },
  computed: {
    ...mapState({
      backgroundColor: (state) => state.application.properties.backgroundColor,
    }),
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      setBusinessCard: "setBusinessCard",
    }),
    async handlerCardRecognition(file) {
      try {
        let param = new FormData(); //创建form对象
        param.append("UserName", "qiuwenyan@eastfair.com");
        param.append("UserPwd", "lkj13*ksjd$kjkj");
        param.append("ProjectType", "Ezt");
        param.append("Project", "fzz");
        param.append("ExhID", "ccle");
        param.append("file", file.file);

        const result = await window.axios.post(
          "https://ai.exporegist.com/v3rec/Api/Card/readcard",
          param,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        );

        if (result.status != "OK" || result.ErrorMsg != "") {
          throw new Error(result.ErrorMsg);
        }

        this.setBusinessCard(result);

        window.$router.push("/verification");
      } catch (error) {
        console.log(error);

        Toast({
          type: "fail",
          message: error.response ? error.response.data.message : error.message,
        });
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.addEventListener("load", () => resolve(reader.result));
          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
</script>

<style lang="less" module>
.btnGroup {
  width: 100%;
  box-sizing: border-box;
  padding: 25px 70px;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .btn {
    width: 100%;
    margin-top: 20px;
  }
}
</style>