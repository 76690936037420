<template>
  <van-button
    :disabled="disabled"
    block
    :color="primaryColor"
    :plain="isReverse"
    :loading="loading"
    @click="(event) => $emit('click', event)"
  >
      <slot></slot>
  </van-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    isReverse: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState({
      primaryColor: (state) => state.application.properties.primaryColor,
    }),
  },
};
</script>